<template>
  <div class="hotels">
    <div class="container textContainer">
        <app-breadcrumbs :breadcrumbs="breadcrumbsList"></app-breadcrumbs>
      <the-main-text
        v-if="countryInfo"
        :title="countryInfo.hotels_name"
        :descr="countryInfo.description"
      ></the-main-text>
      <app-tabs class="mt" :tabList="tabsList"></app-tabs>
    </div>
    <div class="darkBg">
      <div class="container">
        <app-tour-filter
          :data="filterData"
          :filteredItems="selectedItems"
          @change="onFilterChange"
          @onDelete="onFilterDelete"
          @clearAll="onClearAll"
        ></app-tour-filter>
        <div class="hotelList">
          <app-hotel-item
            v-for="(hotel, idx) in hotelList"
            :key="idx"
            :propsData="hotel"
          ></app-hotel-item>
        </div>
        <app-pagination
          v-if="pagiData && pagiData.length > 3"
          :compdata="pagiData"
          @click="onPagiClick"
        ></app-pagination>
        <div v-else class="pagiBlank"></div>
      </div>
    </div>
    <app-see-also :morfTitle="countryInfo.morf" :tabList="tabsList"></app-see-also>
    <app-seo-text v-if="countryInfo.hotels_seo_text" :propsData="countryInfo.hotels_seo_text"></app-seo-text>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AppHotelItem from "../components/common/app-hotel-item.vue";
import AppPagination from "../components/common/app-pagination.vue";
import AppTabs from "../components/common/app-tabs.vue";
import AppTourFilter from "../components/common/app-tour-filter.vue";
import TheMainText from "../components/common/the-main-text.vue";
import AppSeeAlso from "../components/country/app-see-also.vue";

import filterMixin from "@/FilterMixin";
import AppBreadcrumbs from '../components/common/app-breadcrumbs.vue';
import AppSeoText from '../components/common/app-seoText.vue';
export default {
  components: {
    AppTabs,
    AppTourFilter,
    AppSeeAlso,
    AppHotelItem,
    AppPagination,
    TheMainText,
    AppBreadcrumbs,
    AppSeoText
  },
  mixins: [filterMixin],
  data() {
    return {
      tabsList: null,
      countryInfo: null,
      hotelList: null,
      filterData: null,
      breadcrumbsList: null
    };
  },
  computed: {},
  methods: {
    ...mapActions(["lockUi", "unlockUi"]),
    async sendQuery() {
      const query = {};
      const queryToStr = {};
      const keys = [];
      this.selectedItems.forEach((item) => {
        let found = false;
        keys.forEach((key) => {
          if (key == item.key1) found = true;
        });
        if (!found) {
          keys.push(item.groupName);
        }
      });
      keys.forEach((key) => {
        query[key] = [];
        this.selectedItems.forEach((item) => {
          if (key == item.groupName) query[item.groupName].push(item.slug);
        });
      });
      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          queryToStr[key] = query[key].join(",");
        }
      }
      this.$router
        .push({
          name: this.$route.name,
          params: this.$route.params,
          query: queryToStr
        })
        .catch((error) => {
          if (!(error.type == 16)) {
            throw error;
          }
        });
      const res = await this.axios.post("/api/country/get-by-slug", {
        slug: this.$route.params.countryName,
        hotels: query,
        lang: this.currentLang,
        page: this.currentPage
      });
      this.tabsList = res.data.data.tabs;
      this.countryInfo = res.data.data.translate;
      this.hotelList = res.data.data.hotels.data;
      // this.filterData = res.data.data.filter;
    }
  },
  async created() {
    this.lockUi();
    const res = await this.axios.post("/api/country/get-by-slug", {
      slug: this.$route.params.countryName,
      hotels: {},
      lang: this.currentLang,
      page: this.currentPage
    });
    this.tabsList = res.data.data.tabs;
    this.countryInfo = res.data.data.translate;
    this.hotelList = res.data.data.hotels.data;
    this.filterData = res.data.data.filter;
    // this.widgetList = res.data.data.widgets;
    this.breadcrumbsList = res.data.data.breadcrumbs
    document.title = res.data.data.translate.meta_title || res.data.data.translate.hotels_name || "Dream Travel"
    this.currentPage = res.data.data.hotels.current_page;
    this.totalPage = Math.ceil(
      res.data.data.hotels.total / res.data.data.hotels.per_page
    );

    this.unlockUi();
  }
};
</script>

<style lang="sass" scoped>
.hotelList
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 28px
    flex-wrap: wrap
    padding-top: 25px
    // padding-bottom: 60px
    @media (max-width: 1024px)
        justify-content: center
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: 830px)
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: 576px)
        grid-template-columns: 1fr
.darkBg
    padding-top: 30px
.descr
    font-weight: 300
    font-size: 17px
    line-height: 150%
    text-align: center
    color: #FFFFFF
    max-width: 586px
    margin: 0 auto
    margin-bottom: 70px
.textContainer
    text-align: center
    padding-top: 70px
</style>
